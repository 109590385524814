<template>
    <div>
        <div class="scroll-wrapper" :style="{height: chatHeight + 'px'}">
            <vuescroll ref="scrollContainer">
                <div class="frame" ref="frameContainer">
                    <BotMessage v-for="(message, index) in messageListProp"
                                v-on="message.action ? {deleteMessage: deleteMessage, pushLikes: pushLikes,
                    createBlob: createBlob, switchTextarea: switchTextarea, top_questions: top_questions,
                    pushChoice: pushChoice, pushImage: pushImage, setState:setState,
                    pushBtn: pushBtn, back: back, pushQuestions: pushQuestions, delete_old:delete_old,
                    set_campus: set_campus} : {}"
                                :question_id="message.question_id" :index="index"
                                :key="message.id + '_' + index"
                                :body="message.body" :user="message.user" :header="message.header" :type="message.type"
                                :action="message.action">
                    </BotMessage>
                </div>
            </vuescroll>
        </div>
        <div class="tokenfield">
            <form @submit.prevent="formSubmit" @keydown.enter.prevent.exact="formSubmit"
                  @keydown.prevent.ctrl.enter="newLine"
                  class="chat-form">
                <vuescroll>
                    <textarea class="textarea" v-model="textarea_text" :readonly="readonly"
                              :placeholder="textarea_placeholder"/>
                </vuescroll>
                <div class="icon-button">
                    <div class="submit">
                        <button type="submit" value="" style="border: 0; padding: 0">
                            <img src="../assets/submit_icon.svg" alt=""/>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import vuescroll from 'vuescroll';
import hello from '../assets/hello.svg'
import triangle from '../assets/play-arrow.png'
import 'focus-visible';
import BotMessage from './BotMessage.vue';

export default {
    components: {vuescroll, BotMessage},
    props: ['chatHeight'],
    inject: ['questionLevel', 'username', 'messenger', 'campus'],
    data: () => {
        return {
            state: '',
            isOpen: false,
            textarea_text: '',
            readonly: false,
            messageListProp: [],
            for_delete: [],
            email: '',
            triangle: triangle,
            email_campus: 'common',
            textarea_placeholder: '',
            textarea_placeholder_start: 'Нажмите кнопку "Начать диалог"',
            textarea_placeholder_write: 'Введите здесь текст вашего вопроса...',
            textarea_placeholder_choice: 'Выберите один из вариантов',
            textarea_placeholder_email: 'Введите здесь вашу почту',
            textarea_placeholder_campus: 'Выберите один из кампусов',
        }
    },
    methods: {
        urlify(text) {
            let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            return text.replace(urlRegex, function (url) {
                return '<a target="_blank" style="color: #0050cf; border-bottom: 1px solid currentColor" href="' + url + '">' + url + '</a>';
            });
        },
        setState: function (state) {
            this.state = state
        },
        pushBtn: function (text, action) {
            this.for_delete.push(this.messageListProp.length)
            this.messageListProp.push({
                id: this.messageListProp.length,
                body: text,
                type: 'btn',
                action: action
            })
            this.messageScroll()
        },
        back: function (index) {
            this.delete_old()
            this.for_delete = []
            this.setState('')
            this.textarea_placeholder = this.textarea_placeholder_write;
            this.switchTextarea(false);
            this.top_questions()
        },
        delete_old: function () {
            for (const index of this.for_delete.sort().reverse()) {
                this.deleteMessage(index)
            }
            this.for_delete = []
        },
        pushLikes: function (question_id) {
            this.delete_old()
            this.messageListProp.push({
                id: this.messageListProp.length,
                question_id: question_id,
                type: 'likes',
                action: 'likes'
            })
            this.textarea_placeholder = this.textarea_placeholder_choice;
            this.for_delete.push(this.messageListProp.length - 1)
            this.messageScroll()
        },
        set_campus: function (campus) {
            this.email_campus = campus
            this.setState('message')
            this.createBlob('Напишите ваш вопрос.\nМы постараемся вам ответить как можно быстрее.\nВаша текущая почта: ' + this.email, false)
            this.switchTextarea(false)
            this.textarea_placeholder = this.textarea_placeholder_write;
            this.pushBtn('Назад', 'back')
        },
        pushChoice: function (header) {
            this.switchTextarea(true);
            this.messageListProp.push({
                id: this.messageListProp.length,
                header: header,
                type: 'choice',
                action: 'choice'
            })
            this.textarea_placeholder = this.textarea_placeholder_choice;
            this.for_delete.push(this.messageListProp.length - 1)
            this.messageScroll()
        },
        pushImage: function (path) {
            this.messageListProp.push({
                id: this.messageListProp.length,
                type: 'image',
                body: path
            })
            this.messageScroll()
        },
        deleteMessage: function (index) {
            this.messageListProp.splice(index, 1);
        },
        top_questions: function () {
            let that = this;
            this.$axios.post(`/top/${this.questionLevel}`, {
                messenger: this.messenger,
                username: this.username,
                campus: this.campus
            }).then(function (response) {
                that.createBlob('Популярные вопросы:', false)
                that.messageListProp.push({
                    id: that.messageListProp.length,
                    type: 'questions',
                    body: response.data,
                    // header: 'Популярные вопросы:',
                    action: 'createBlob'
                })
                that.for_delete.push(that.messageListProp.length - 1)
                that.textarea_placeholder = that.textarea_placeholder_write;
                that.messageScroll()
            })
        },
        pushQuestions: function (data, header) {
            this.messageListProp.push({
                id: this.messageListProp.length,
                type: 'questions',
                body: data,
                header: header,
                action: 'createBlob'
            })
            this.messageScroll()
            this.for_delete.push(this.messageListProp.length - 1)
        },
        pushCampus: function () {
            this.messageListProp.push({
                id: this.messageListProp.length,
                type: 'campus',
                action: 'createBlob'
            })
            this.messageScroll()
            this.switchTextarea(true)
            this.textarea_placeholder = this.textarea_placeholder_campus;
            this.for_delete.push(this.messageListProp.length - 1)
        },
        search: function (question_text) {
            let that = this;
            this.$axios.post(`/search_questions/${this.questionLevel}`, {
                messenger: this.messenger,
                username: this.username,
                campus: this.campus,
                question_text: question_text
            }).then(function (response) {
                if (response.data.list) {
                    that.messageListProp.push({
                        id: that.messageListProp.length,
                        type: 'questions',
                        body: response.data,
                        header: 'Наиболее похожие вопросы:',
                        action: 'createBlob'
                    });
                    that.messageScroll()
                } else {
                    that.createBlob('Похожие вопросы не найдены', false)
                    that.messageScroll()
                    that.createBlob('Попробуйте уточнить вопрос или оставьте адрес вашей электронной почты, и мои коллеги ответят вам позднее', false)
                    that.messageScroll()
                    that.pushChoice('Хотите получить ответ по электронной почте?')
                }
            })
            this.messageScroll()
        },
        switchTextarea: function (value) {
            if (value !== undefined) {
                this.readonly = value
            } else {
                this.readonly = !this.readonly
            }
        },
        createBlob: function (text, user, index) {
            this.delete_old()
            if (text === 'Попробуйте переформулировать вопрос' || text.includes("Мне понравилось наше общение")) {
                this.textarea_placeholder = this.textarea_placeholder_write;
            }
            if (text === 'Введите адрес вашей электронной почты') {
                this.textarea_placeholder = this.textarea_placeholder_email;
            }
            this.messageListProp.push(
                {
                    id: this.messageListProp.length,
                    body: this.urlify(text),
                    user: user,
                    type: 'message'
                }
            )
            this.messageScroll()
        },
        formSubmit: function () {
            if (this.textarea_text !== '' && !this.readonly) {
                if (this.state === 'email') {
                    let that = this;
                    this.$axios.post(`/update_user_email`, {
                        messenger: this.messenger,
                        username: this.username,
                        campus: this.campus,
                        email: this.textarea_text
                    }).then(function (response) {
                        if (response.data.status) {
                            that.email = that.textarea_text
                            that.createBlob(that.textarea_text, true)
                            that.textarea_text = ''
                            that.setState('campus')
                            that.delete_old()
                            if (import.meta.env.VITE_FINBLOCK) {
                                that.set_campus('moscow')
                            } else {
                                that.pushCampus()
                                that.pushBtn('Назад', 'back')
                            }
                        } else {
                            that.createBlob('Почта не валидна. Введите правильную почту', false)
                            that.pushBtn('Назад', 'back')
                        }
                    })
                    return
                }
                if (this.state === 'message') {
                    let that = this;
                    this.$axios.post(`/send_email`, {
                        messenger: this.messenger,
                        username: this.username,
                        campus: this.email_campus,
                        email: this.email,
                        text: this.textarea_text
                    }).then(function (response) {
                        that.createBlob(that.textarea_text, true)
                        that.createBlob('Ваше сообщение отправлено в службу поддержки', false)
                        that.delete_old()

                        that.textarea_text = ''
                        that.setState('')
                        that.createBlob('У вас остались еще ко мне вопросы?', false)
                        that.pushChoice()
                    })
                    return;
                }

                this.delete_old()
                let question_text = this.textarea_text
                this.messageListProp.push(
                    {
                        id: this.messageListProp.length,
                        body: question_text,
                        user: true,
                        type: 'message'
                    }
                )
                this.textarea_text = ''
                this.messageScroll()
                this.createBlob('Отправляюсь на поиски...', false)
                this.messageScroll()
                this.search(question_text)
            }
        },
        messageScroll() {
            let chat = this.$refs['frameContainer'],
                scrollContainer = this.$refs['scrollContainer'];
            scrollContainer.scrollTo({y: chat.scrollHeight});
        },
        newLine(e) {
            let caret = e.target.selectionStart;
            e.target.setRangeText("\n", caret, caret, "end");
            this.text = e.target.value;
        }
    },
    mounted() {
        this.$axios.defaults.baseURL = import.meta.env.VITE_BACKEND_API_URL
        this.pushImage(hello);
        this.createBlob('Добрый день!\nЧем я могу вам помочь?', false)
        this.delete_old()
        this.top_questions()
        if (this.messageListProp) {
            this.messageScroll()
        }
    }
}
</script>
<style lang="css">
.__bar-is-vertical {
    right: -10px !important;
}

.__hidebar {
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox */
}
</style>
<style scoped lang="scss">
@import '@hse-design/core/lib/index.css';
@import 'sanitize.css';
@import 'sanitize.css/forms.css';
@import 'sanitize.css/typography.css';
@import '@hse-design/core/lib/vue/index.css';

.scroll-wrapper {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 48px;
  bottom: 88px;
  //overflow-y: scroll;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  position: absolute;
  min-width: 312px;
  left: 0px;
  top: 16px;
}

.tokenfield {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 8px 8px 8px 12px;
  gap: 10px;
  position: absolute;
  height: 56px;
  left: 16px;
  right: 16px;
  bottom: 16px;
  background: #FFFFFF;
  border: 1px solid #D3D8E6;
  border-radius: 8px;
}

.icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 40px;
  left: -4px;
  height: 40px;
  position: relative;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.submit {
  cursor: pointer;
  width: 24px;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.textarea::-webkit-scrollbar {
  width: 1em;
}

.textarea {
  width: 262px;
  height: 40px;
  font-family: 'HSE Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #6B7A99;
  flex: none;
  order: 0;
  flex-grow: 1;
  border: none;
  outline: none;
  resize: none;
  border-radius: 0;
  //overflow: auto;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0 8px 0 0;
}

::-webkit-input-placeholder {
  font-style: initial;
}

:-moz-placeholder {
  font-style: initial;
}

::-moz-placeholder {
  font-style: initial;
}

:-ms-input-placeholder {
  font-style: initial;
}

.chat-form {
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>