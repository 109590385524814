
<div>
    <div class="scroll-wrapper" :style="{height: chatHeight + 'px'}">
        <vuescroll ref="scrollContainer">
            <div class="frame" ref="frameContainer">
                <BotMessage v-for="(message, index) in messageListProp"
                            v-on="message.action ? {deleteMessage: deleteMessage, pushLikes: pushLikes,
                createBlob: createBlob, switchTextarea: switchTextarea, top_questions: top_questions,
                pushChoice: pushChoice, pushImage: pushImage, setState:setState,
                pushBtn: pushBtn, back: back, pushQuestions: pushQuestions, delete_old:delete_old,
                set_campus: set_campus} : {}"
                            :question_id="message.question_id" :index="index"
                            :key="message.id + '_' + index"
                            :body="message.body" :user="message.user" :header="message.header" :type="message.type"
                            :action="message.action">
                </BotMessage>
            </div>
        </vuescroll>
    </div>
    <div class="tokenfield">
        <form @submit.prevent="formSubmit" @keydown.enter.prevent.exact="formSubmit"
              @keydown.prevent.ctrl.enter="newLine"
              class="chat-form">
            <vuescroll>
                <textarea class="textarea" v-model="textarea_text" :readonly="readonly"
                          :placeholder="textarea_placeholder"/>
            </vuescroll>
            <div class="icon-button">
                <div class="submit">
                    <button type="submit" value="" style="border: 0; padding: 0">
                        <img src="../assets/submit_icon.svg" alt=""/>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
