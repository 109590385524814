<template>
  <div v-if="type === 'message'" class="message message-text" :class="user ? 'user' : 'bot'" v-html="body">
      {{ body }}
  </div>
  <img v-else-if="type === 'image'" :src="body" class="img" alt=""/>
    <div v-else-if="type === 'btn'" class="btn" @click="$emit(action, index)"><div class="btn-text">{{ body }}</div></div>
  <div class="btn_yes_no-frame" v-else-if="type === 'likes'">
    <div class="message message-text bot">Ответ оказался полезным?</div>
    <div class="btn_yes_no">
      <div class="bubble-btn-yes" @click="like(true)"><div class="bubble-btn-text-yes">Да👍</div></div>
      <div class="bubble-btn-no" @click="like(false)"><div class="bubble-btn-text-no">Нет👎</div></div>
    </div>
  </div>
  <div class="btn_yes_no-frame" v-else-if="type === 'choice'">
    <div v-if="header" class="message message-text bot">{{ header }}</div>
    <div class="btn_yes_no">
      <div class="bubble-btn-yes" style="padding: 8px 20px;" @click="choice(true, header)"><div class="bubble-btn-text-yes">Да</div></div>
      <div class="bubble-btn-no" style="padding: 8px 20px;" @click="choice(false, header)"><div class="bubble-btn-text-no">Нет</div></div>
    </div>
  </div>
  <div v-else-if="type === 'questions'">
    <div class="bot_questions">
      <div v-if="header" class="message message-text bot">{{ header }}</div>
      <div v-for="question in body.list" :key="question.id + '-' + index" class="bot_question"
           @click="answer(question.id, body.log_pk, index)"><div class="bot_question-text">{{ question.text }}</div>
      </div>
    </div>
  </div>
  <div v-else-if="type === 'campus'">
    <div class="bot_questions">
      <div class="message message-text bot">Выберите кампус, к которому относится вопрос</div>
      <div class="bot_question" @click="$emit('set_campus', 'moscow')"><div class="bot_question-text">Москва</div></div>
      <div class="bot_question" @click="$emit('set_campus', 'spb')"><div class="bot_question-text">Санкт-Петербург</div></div>
      <div class="bot_question" @click="$emit('set_campus', 'perm')"><div class="bot_question-text">Пермь</div></div>
      <div class="bot_question" @click="$emit('set_campus', 'nnov')"><div class="bot_question-text">Нижний Новгород</div></div>
    </div>
  </div>
</template>

<script>
import findImg from '../assets/find.png'
import heartImg from '../assets/heart.png'
import ihseImg from '../assets/i_hse.png'

import 'focus-visible';

export default {
  props: ['body', 'user', 'type', 'action', 'question_id', 'header', 'index'],
  inject: ['questionLevel', 'username', 'messenger', 'campus'],
  methods: {
    like: function (rating) {
      this.$axios.post(`/update_question_rating/${this.questionLevel}`, {
        username: this.username,
        question_id: this.question_id,
        rating: rating,
        messenger: this.messenger,
        campus: this.campus
      })
      this.$emit('delete_old')
      if (rating) {
        this.$emit('createBlob', 'Да', true)
        this.$emit('createBlob', 'Отлично! Остались ли у вас еще вопросы?', false)
        this.$emit('pushChoice')
      } else {
        this.$emit('createBlob', 'Нет', true)
        this.$emit('createBlob', 'Попробуйте уточнить вопрос или оставьте адрес вашей электронной почты, и мои коллеги ответят вам позднее', false)
        this.$emit('pushChoice', 'Хотите получить ответ по электронной почте?')
      }
    },
    choice: function (choice, header) {
      this.$emit('delete_old')
      if (header === undefined) {
        if (choice) {
          this.$emit('switchTextarea')
          this.$emit('createBlob', 'Да', true)
          this.$emit('pushImage', findImg)
          this.$emit('top_questions')
        } else {
          this.$emit('switchTextarea')
          this.$emit('createBlob', 'Нет', true)
          this.$emit('createBlob', 'Мне понравилось наше общение! Заходите еще, если у вас появятся вопросы!\nБот разработан студентами и выпускниками МИЭМ НИУ ВШЭ.', false)
          this.$emit('pushImage', heartImg)
        }
      } else {
        if (choice) {
          this.$emit('createBlob', 'Да', true)
          this.$emit('createBlob', 'Введите адрес вашей электронной почты', false)
          this.$emit('setState', 'email')
          this.$emit('pushBtn', 'Назад', 'back')
        } else {
          this.$emit('createBlob', 'Нет', true)
          this.$emit('pushImage', ihseImg)
          this.$emit('createBlob', 'Попробуйте переформулировать вопрос', false)
        }
        this.$emit('switchTextarea');
      }
    },
    answer: function (question_id, log_pk, index) {
      this.$emit('switchTextarea', true)
      let that = this;
      this.$axios.get(`/question_answer/${question_id}/${this.questionLevel}/${log_pk}`).then(function (response) {
        that.$emit('createBlob', response.data.text, true, index)
        let link = response.data.link || ''
        that.$emit('createBlob', response.data.answer + ' ' + link, false)
        that.$emit('delete_old')
        that.$emit('pushLikes', question_id)
        if (response.data.linked_question) {
          that.$emit('pushQuestions', {
            'list': [response.data.linked_question],
            log_pk: 0
          }, 'Вам может быть также интересно:')
        }
      })
    }
  },
  mounted() {
    this.$axios.defaults.baseURL = import.meta.env.VITE_BACKEND_API_URL
  }
}
</script>

<style scoped lang="scss">
@import 'sanitize.css';
@import 'sanitize.css/forms.css';
@import 'sanitize.css/typography.css';
@import '@hse-design/core/lib/index.css';
@import '@hse-design/core/lib/vue/index.css';

.bot_questions {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  box-sizing: border-box;
  align-items: flex-start;
  gap: 16px;
  width: 312px;
  background: #FFFFFF;
  flex: none;
  order: 3;
  flex-grow: 0;
}

.bot_question {
  width: 312px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;
  gap: 10px;
  background: #FFFFFF;
  border: 1px solid #8EB2EC;
  border-radius: 16px;
  flex: none;
  order: 3;
  flex-grow: 0;
}

.bot_question-text {
  width: 100%;
  font-family: 'HSE Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0050CF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.bot_question:hover {
  background: #8EB2EC;
}

.img {
  width: 80px;
  height: 80px;
  flex: none;
  order: 0;
  flex-grow: 0
}

.btn {
  //align-self: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 132px 0px 0px;
  gap: 8px;
  width: 312px;
  height: 36px;
  flex: none;
  order: 16;
  flex-grow: 0;
  box-sizing: border-box;
  padding: 8px 12px;
  gap: 10px;
  width: 67px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #8EB2EC;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.btn:hover {
  background: #8EB2EC;
  cursor: pointer;
}

.btn-text {
  width: 43px;
  height: 20px;
  font-family: 'HSE Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0050CF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.btn_yes_no-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.btn_yes_no {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 132px 0px 0px;
  gap: 8px;
  width: 312px;
  height: 36px;
  flex: none;
  flex-grow: 0;
}

.bubble-btn-yes:hover {
  background: #8EB2EC;
  cursor: pointer;
}
.bubble-btn-no:hover {
  background: #8EB2EC;
  cursor: pointer;
}

.bubble-btn-yes {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 10px;
  gap: 10px;
  //width: 61px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #8EB2EC;
  border-radius: 16px;
  flex: none;
  flex-grow: 0;
}

.bubble-btn-text-yes {
  //width: 40px;
  height: 20px;
  font-family: 'HSE Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0050CF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.bubble-btn-text-no {
  //width: 44px;
  height: 20px;
  font-family: 'HSE Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0050CF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.bubble-btn-no {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 10px;
  gap: 10px;
  //width: 68px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #8EB2EC;
  border-radius: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.message {
  font-size: 14px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.bot {
  align-self: flex-start;
  padding: 8px 12px;
  gap: 16px;
  background: #EDEFF3;
  border-radius: 16px 16px 16px 0;
  //display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: none;
  flex-grow: 0;
}

.user {
  align-self: flex-end;
  //display: flex;
  flex-direction: row;
  flex: none;
  flex-grow: 0;
  padding: 8px 12px;
  gap: 16px;
  background: #C2DAFF;
  border-radius: 16px 16px 0px 16px;
  flex: none;
}

.message-text {
  font-family: 'HSE Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  overflow-wrap: anywhere;
  //display: flex;
  align-items: center;
  color: #0F0F14;
  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>