<template>
  <div>
    <BotBlob v-if="expand" :expand="expand" @expand="expand = !expand"/>
    <div v-else class="chat-container"
         :style="{'max-width': chatWidth + 'px', 'max-height': chatHeight + 'px'}">
      <BotHeader ref="chatHeader" @expand="expand = !expand"/>
      <BotMessageWindow :chatHeight="chatWindowHeight"/>
    </div>
  </div>
</template>

<script>
import BotHeader from './BotHeader.vue'
import BotBlob from './BotBlob.vue'
import BotMessageWindow from './BotMessageWindow.vue'

export default {
  components: {BotHeader, BotBlob, BotMessageWindow},
  data: () => {
    return {
      expand: true,
      chatHeight: 0,
      chatWindowHeight: 0,
      chatWidth: 0,
      textarea_text: ""
    }
  },
  created() {
    window.addEventListener("resize", this.ResizeHandler);
    this.ResizeHandler();
  },
  destroyed() {
    window.removeEventListener("resize", this.ResizeHandler);
  },
  methods: {
    ResizeHandler(e) {
      this.chatHeight = window.innerHeight - 40;
      this.chatWidth = window.innerWidth - 10;
      this.chatWindowHeight = Math.min(600, this.chatHeight) - 136;
    }
  }
}
</script>

<style scoped lang="scss">
@import 'sanitize.css';
@import 'sanitize.css/forms.css';
@import 'sanitize.css/typography.css';
@import '@hse-design/core/lib/index.css';
@import '@hse-design/core/lib/vue/index.css';

.chat-container {
  height: 600px;
  display: block;
  z-index: 13;
  background: white;
  align-items: center;
  position: fixed;
  bottom: 32px;
  right: 32px;
  max-width: 344px;
  transform-origin: right bottom;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  border-radius: 12px;
}
</style>