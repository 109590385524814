<template>
  <div class="open-chat" @click="$emit('expand')">
    <img class="crow_avatar" src="../assets/crow_avatar.png" alt=""/>
  </div>
</template>

<script>
import 'focus-visible';

export default {
  props: ["expand"]
}
</script>

<style scoped lang="scss">
@import 'sanitize.css';
@import 'sanitize.css/forms.css';
@import 'sanitize.css/typography.css';
@import '@hse-design/core/lib/index.css';
@import '@hse-design/core/lib/vue/index.css';

.open-chat {
  position: fixed;
  z-index: 6;
  background: white;
  bottom: 32px;
  right: 32px;
  width: 80px;
  height: 80px;
  border-radius: 200px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.crow_avatar {
  width: 80px;
  height: 80px;
}
</style>