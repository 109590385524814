
<div class="header">
    <div class="chat_head">
        <div v-if="use_tg" class="icon-button" @click="open_tg">
            <div class="telegram">
                <img src="../assets/telegram.svg" alt=""/>
            </div>
        </div>
        <div v-if="use_vk" class="icon-button" @click="open_vk">
            <div class="vk">
                <img src="../assets/vk.svg" alt=""/>
            </div>
        </div>
        <div class="icon-secondary" @click="open_faq">
            <div class="icon-secondary-text">FAQ
                <div class="link-open-small"><img class="faq-icon" src="../assets/faq.svg" alt=""></div>
            </div>
        </div>
    </div>
    <div class="close" @click="$emit('expand')">
        <div class="icon-button">
            <div class="close-medium"><img src="../assets/close.svg" alt=""/></div>
        </div>
    </div>
</div>
