
<div v-if="type === 'message'" class="message message-text" :class="user ? 'user' : 'bot'" v-html="body">
    {{ body }}
</div>
<img v-else-if="type === 'image'" :src="body" class="img" alt=""/>
  <div v-else-if="type === 'btn'" class="btn" @click="$emit(action, index)"><div class="btn-text">{{ body }}</div></div>
<div class="btn_yes_no-frame" v-else-if="type === 'likes'">
  <div class="message message-text bot">Ответ оказался полезным?</div>
  <div class="btn_yes_no">
    <div class="bubble-btn-yes" @click="like(true)"><div class="bubble-btn-text-yes">Да👍</div></div>
    <div class="bubble-btn-no" @click="like(false)"><div class="bubble-btn-text-no">Нет👎</div></div>
  </div>
</div>
<div class="btn_yes_no-frame" v-else-if="type === 'choice'">
  <div v-if="header" class="message message-text bot">{{ header }}</div>
  <div class="btn_yes_no">
    <div class="bubble-btn-yes" style="padding: 8px 20px;" @click="choice(true, header)"><div class="bubble-btn-text-yes">Да</div></div>
    <div class="bubble-btn-no" style="padding: 8px 20px;" @click="choice(false, header)"><div class="bubble-btn-text-no">Нет</div></div>
  </div>
</div>
<div v-else-if="type === 'questions'">
  <div class="bot_questions">
    <div v-if="header" class="message message-text bot">{{ header }}</div>
    <div v-for="question in body.list" :key="question.id + '-' + index" class="bot_question"
         @click="answer(question.id, body.log_pk, index)"><div class="bot_question-text">{{ question.text }}</div>
    </div>
  </div>
</div>
<div v-else-if="type === 'campus'">
  <div class="bot_questions">
    <div class="message message-text bot">Выберите кампус, к которому относится вопрос</div>
    <div class="bot_question" @click="$emit('set_campus', 'moscow')"><div class="bot_question-text">Москва</div></div>
    <div class="bot_question" @click="$emit('set_campus', 'spb')"><div class="bot_question-text">Санкт-Петербург</div></div>
    <div class="bot_question" @click="$emit('set_campus', 'perm')"><div class="bot_question-text">Пермь</div></div>
    <div class="bot_question" @click="$emit('set_campus', 'nnov')"><div class="bot_question-text">Нижний Новгород</div></div>
  </div>
</div>
