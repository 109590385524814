<template>
    <div class="header">
        <div class="chat_head">
            <div v-if="use_tg" class="icon-button" @click="open_tg">
                <div class="telegram">
                    <img src="../assets/telegram.svg" alt=""/>
                </div>
            </div>
            <div v-if="use_vk" class="icon-button" @click="open_vk">
                <div class="vk">
                    <img src="../assets/vk.svg" alt=""/>
                </div>
            </div>
            <div class="icon-secondary" @click="open_faq">
                <div class="icon-secondary-text">FAQ
                    <div class="link-open-small"><img class="faq-icon" src="../assets/faq.svg" alt=""></div>
                </div>
            </div>
        </div>
        <div class="close" @click="$emit('expand')">
            <div class="icon-button">
                <div class="close-medium"><img src="../assets/close.svg" alt=""/></div>
            </div>
        </div>
    </div>
</template>

<script>
import 'focus-visible';

export default {
    inject: ['questionLevel'],
    data: () => {
        return {
          use_tg: true,
          use_vk: true,
        }
    },
    methods: {
        open_faq: function () {
            if (import.meta.env.VITE_BACKEND_API_URL.includes('bot.fd.hse.ru')) {
                window.open('https://fd.hse.ru/', '_blank');
            } else if (this.questionLevel === 'ba') {
                window.open('https://ba.hse.ru/faq#pagetop', '_blank');
            } else if (this.questionLevel === 'ma') {
                window.open('https://ma.hse.ru/faq#pagetop', '_blank');
            }
        },
        open_tg: function () {
            if (this.questionLevel === 'ba') {
                window.open(import.meta.env.VITE_BA_TG_BOT, '_blank');
            } else if (this.questionLevel === 'ma') {
                window.open(import.meta.env.VITE_MA_TG_BOT, '_blank');
            }
        },
        open_vk: function () {
            if (this.questionLevel === 'ba') {
                window.open(import.meta.env.VITE_BA_VK_BOT, '_blank');
            } else if (this.questionLevel === 'ma') {
                window.open(import.meta.env.VITE_MA_VK_BOT, '_blank');
            }
        },
    },
    mounted() {
        if (this.questionLevel === "ba") {
            this.use_tg = Boolean(import.meta.env.VITE_BA_TG_BOT)
            this.use_vk = Boolean(import.meta.env.VITE_BA_VK_BOT)
        } else if (this.questionLevel === "ma") {
            this.use_tg = Boolean(import.meta.env.VITE_MA_TG_BOT)
            this.use_vk = Boolean(import.meta.env.VITE_MA_VK_BOT)
        }
    }
}
</script>

<style scoped lang="scss">
@import 'sanitize.css';
@import 'sanitize.css/forms.css';
@import 'sanitize.css/typography.css';
@import '@hse-design/core/lib/index.css';
@import '@hse-design/core/lib/vue/index.css';

.header {
    min-width: 344px;
}
.chat_head {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;

  position: relative;
  width: 150px;
  height: 40px;
  left: 16px;
  top: 8px;
}

.icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.telegram {
  width: 24px;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.vk {
  width: 24px;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.icon-secondary {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 4px;
  width: 70px;
  height: 40px;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.icon-secondary-text {
  width: 34px;
  height: 24px;
  font-family: 'HSE Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  margin-top: 4px;
  line-height: 24px;
  color: #D3D8E6;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.link-open-small {
  width: 16px;
  height: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.faq-icon {
  position: relative;
  width: 10px;
  margin-top: 1px;
  height: 10px;
  right: -40px;
  top: -28px;
}

.close {
  cursor: pointer;
  width: 24px;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
  position: absolute;
  width: 14px;
  height: 14px;
  right: 30px;
  top: 9px;
}
</style>