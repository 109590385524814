import Vue from 'vue'
import VueCompositionAPI, {createApp, h} from '@vue/composition-api'
import vuescroll from 'vuescroll';

import 'focus-visible';
import App from './App.vue';
import axios from 'axios';

Vue.use(VueCompositionAPI)
Vue.use(vuescroll);
Vue.prototype.$vuescrollConfig = {
    bar: {
        background: '#0060CF'
    }
};
const axiosConfig = {
    timeout: 30000,
};
Vue.prototype.$axios = axios.create(axiosConfig)

function get_client_id() {
    let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
    let raw = (match) ? decodeURIComponent(match[1]) : null;
    if (raw) {
        match = raw.match(/(\d+\.\d+)$/);
    }
    let gacid = (match) ? match[1] : null;
    if (gacid) {
        return gacid;
    }
}

const campuses = ['moscow', 'spb', 'perm', 'nnov']
const element_id = 'abiturbot'
const el = document.getElementById(element_id);
const questionLevel = el.classList.contains('ma') ? 'ma' : 'ba';
let campus = campuses[0];
if (el.classList.contains(campuses[3])) {
    campus = campuses[3]
} else if (el.classList.contains(campuses[2])) {
    campus = campuses[2]
} else if (el.classList.contains(campuses[1])) {
    campus = campuses[1]
}
const app = createApp({
    render: () => h(App),
    provide: {
        questionLevel: questionLevel,
        username: get_client_id() || '',
        messenger: 'site_' + questionLevel,
        campus: campus
    }
})

app.mount('#' + element_id)
